export const BackIcon = ({ fill, size, height, width, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.3 5.4C6.52091 5.23431 6.56569 4.92091 6.4 4.7C6.23431 4.47909 5.92091 4.43431 5.7 4.6L1.70465 7.59651C1.67573 7.61771 1.64918 7.64195 1.62546 7.66875C1.54739 7.75695 1.5 7.87294 1.5 8C1.5 8.12706 1.54739 8.24305 1.62546 8.33125C1.63692 8.3442 1.64908 8.3566 1.66192 8.36838C1.67549 8.38084 1.68976 8.39257 1.70465 8.40349L5.7 11.4C5.92091 11.5657 6.23431 11.5209 6.4 11.3C6.56569 11.0791 6.52091 10.7657 6.3 10.6L3.5 8.5L14 8.5C14.2761 8.5 14.5 8.27614 14.5 8C14.5 7.72386 14.2761 7.5 14 7.5L3.5 7.5L6.3 5.4Z"
        fill="white"
      />
    </svg>
  );
};
